import React, { useEffect } from "react";
import styles from "./Navbar.module.scss";

import { Divide as Hamburger } from "hamburger-react";

import { useLocation } from "react-router-dom";

export default function Navbar({ isOpen, setOpen }) {
  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      setOpen(false);
    }
  }, [location, setOpen]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <Hamburger toggled={isOpen} toggle={setOpen} color="#FFF" />
      </div>
    </div>
  );
}
