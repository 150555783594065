import React from "react";
import styles from "./Button.module.scss";

import clsx from "clsx";

export default function Button(props) {
  const { type, text, className } = props;
  return (
    <button
      {...props}
      className={clsx(styles.button, className, type === "purple" && styles.purple, type === "white" && styles.white)}
    >
      <div></div>
      <p>{text}</p>
    </button>
  );
}
