import "./App.css";
import Home from "./components/Home";
import Sidebar from "./components/Sidebar";
import "./_global/global.scss";

import Navbar from "components/Navbar";
import {  useState } from "react";

import { BrowserRouter } from "react-router-dom";

function App() {
  const [isOpen, setOpen] = useState(false);



  return (
    <div className="App">
      <BrowserRouter>
        <Navbar isOpen={isOpen} setOpen={setOpen} />
        <Sidebar isOpen={isOpen} />
        <Home />
      </BrowserRouter>
    </div>
  );
}

export default App;
