import React, { useState } from "react";
import styles from "./Art.module.scss";

import one from "assets/art/1.png";
import two from "assets/art/2.png";
import three from "assets/art/3.png";
import four from "assets/art/4.png";
import five from "assets/art/5.png";
import six from "assets/art/6.png";
import Button from "./shared/Button";
import PictureModal from "./shared/PictureModal";

const gallery1 = [
  {
    id: 1,
    image: four,
    text: "Clouded",
  },
  {
    id: 2,
    image: two,
    text: "Expedition",
  },
  {
    id: 3,
    image: three,
    text: "Naked",
  },
];

const gallery2 = [
  {
    id: 4,
    image: one,
    text: "Universe",
  },
  {
    id: 5,
    image: five,
    text: "Leap",
  },
  {
    id: 6,
    image: six,
    text: "Serenity",
  },
];

export default function Art() {
  const [pictureModal, setPictureModal] = useState({
    isOpen: false,
    image: null,
    text: null,
  });

  function openModal(image) {
    setPictureModal({
      isOpen: true,
      image: image.image,
      text: image.text,
    });
  }

  function closeModal() {
    setPictureModal({
      isOpen: false,
      image: null,
      text: null,
    });
  }

  function handleFormChange(e) {}

  function handleFormSubmit(e) {
    e.preventDefault();
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.art} id="art">
        <div className={styles.heading}>Art</div>

        <div className={styles.grid}>
          <div className={styles.row}>
            {gallery1.map((item) => (
              <div key={item.id} className={styles.image} onClick={openModal.bind(this, item)}>
                <img src={item.image} alt="" />
                <p>{item.text}</p>
              </div>
            ))}
          </div>
          <div className={styles.row}>
            {gallery2.map((item) => (
              <div key={item.id} className={styles.image} onClick={openModal.bind(this, item)}>
                <img src={item.image} alt="" />
                <p>{item.text}</p>
              </div>
            ))}
          </div>
          <div className={styles.controller}>
            <Button
              text="View More"
              type="purple"
              onClick={() => window.open("https://www.instagram.com/kalaveethih/")}
            />
          </div>
        </div>
      </div>

      <div className={styles.contact} id="contact">
        <div className={styles.heading}>Contact</div>

        <div className={styles.form}>
          <form autoComplete="off" onChange={handleFormChange} onSubmit={handleFormSubmit}>
            <label>Name</label>
            <input type="text" />
            <label>Email</label>
            <input type="email" />
            <label>Message</label>
            <textarea></textarea>
            <Button text="submit" type="purple" className={styles.formController} />
          </form>
        </div>
      </div>

      {pictureModal.isOpen && pictureModal.image && pictureModal.text && (
        <PictureModal image={pictureModal.image} text={pictureModal.text} onClose={closeModal} />
      )}
    </div>
  );
}
