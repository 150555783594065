import React from "react";
import Button from "./Button";
import styles from "./PictureModal.module.scss";

export default function PictureModal({ image, text, onClose }) {
  return (
    <div className={styles.backdrop}>
      <div className={styles.polaroid}>
        <img src={image} alt="" />
        <p>{text}</p>
      </div>
      <Button onClick={onClose} text="close" type="white" className={styles.controller} />
    </div>
  );
}
