import React from "react";
import styles from "./Sidebar.module.scss";

import atimabh from "assets/atimabh.jpeg";
import clsx from "clsx";

import { HashLink } from "react-router-hash-link";

import { BsGithub, BsInstagram, BsLinkedin } from "react-icons/bs";

export default function Sidebar({ isOpen }) {
  const scrollWithOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -80;
    window.scrollTo({ top: yCoordinate + yOffset, behavior: "smooth" });
  };

  return (
    <div className={clsx(styles.wrapper, isOpen && styles.isOpen)}>
      <div className={styles.display}>
        <img src={atimabh} alt="" />
      </div>
      <p className={styles.name}>Atimabh Barunaha</p>

      <div className={styles.links}>
        <p>
          <HashLink to="#home" smooth scroll={(el) => scrollWithOffset(el)}>
            Home
          </HashLink>
        </p>
        <p>
          <HashLink to="#work" smooth scroll={(el) => scrollWithOffset(el)}>
            Work
          </HashLink>
        </p>
        <p>
          <HashLink to="#skills" smooth scroll={(el) => scrollWithOffset(el)}>
            Skills
          </HashLink>
        </p>
        <p>
          <HashLink to="#art" smooth scroll={(el) => scrollWithOffset(el)}>
            Art Gallery
          </HashLink>
        </p>
        <p>
          <HashLink to="#contact" smooth scroll={(el) => scrollWithOffset(el)}>
            Contact
          </HashLink>
        </p>
      </div>
      <div className={styles.socials}>
        <button onClick={() => window.open("https://github.com/Atimabh")}>
          <BsGithub />
        </button>
        <button  onClick={() => window.open("https://www.instagram.com/kalaveethih/")}>
          <BsInstagram />
        </button>
        <button onClick={() => window.open("https://www.linkedin.com/in/barunaha/")}>
          <BsLinkedin />
        </button>
      </div>
    </div>
  );
}
