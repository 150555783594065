import React from "react";
import styles from "./Hero.module.scss";
import Button from "./shared/Button";

import wavinghand from "assets/waving-hand.png";

export default function Hero() {
  return (
    <div className={styles.wrapper} id="home">
      <div className={styles.content}>
        <div className={styles.hello}>
          HELLO{" "}
          <div className={styles.image}>
            <img src={wavinghand} alt="" />
          </div>{" "}
        </div>
        <p className={styles.description}>
          I am Atimabh Barunaha, a fullstack web and app developer from India, currently building{" "}
          <span onClick={() => window.open("https://www.reverselabz.com/")}>@reverselabz</span>
        </p>
        <div className={styles.controller}>
          <Button
            text="Get in touch"
            type="purple"
            onClick={() => {
              // const element = document.getElementById("contact");
              // element.scrollIntoView();
              window.open("https://mail.google.com/mail/?view=cm&fs=1&to=abarunaha@gmail.com")
            }}
          />
        </div>
      </div>
    </div>
  );
}
