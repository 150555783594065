import React from "react";
import styles from "./Experience.module.scss";

export default function Experience() {
  return (
    <div className={styles.wrapper}>
      <div className={styles.experience} id="work">
        <div className={styles.heading}>Work</div>
        <div className={styles.timelineWrapper}>
          <div className={styles.timeline}>
            <div className={styles.line}>
              <div></div>
            </div>
            <div className={styles.info}>
              <p>Apr'22 - Present</p>
              <p>
                Reverse Labz <span>(Founding Engineer)</span>{" "}
              </p>
              <p>
                Did you know that, diabetes, high cholesterol, hypertension and many other chronic diseases are
                reversible? <br /> We are on a mission to reverse chronic health diseases and impact millions of lives.
              </p>
            </div>
          </div>
          <div className={styles.timeline}>
            <div className={styles.line}>
              <div></div>
            </div>
            <div className={styles.info}>
              <p>Nov'21 - Mar'22</p>
              <p>
                FastJobs.io <span>(Frontend Developer)</span>
              </p>
              <p>
                Worked on the core features of FastJobs.io platform. Built responsive user facing web apps and set up
                the content management system for internal use
              </p>
            </div>
          </div>
          <div className={styles.timeline}>
            <div className={styles.line}>
              <div></div>
            </div>
            <div className={styles.info}>
              <p>May'21 - Jun'21</p>
              <p>
                FanClash <span>(Software Development Engineer)</span>
              </p>
              <p>
                Built the entire FanClash.in website from scratch and worked on new user facing features for the android
                app
              </p>
            </div>
          </div>
          <div className={styles.timeline}>
            <div className={styles.line}>
              <div></div>
            </div>
            <div className={styles.info}>
              <p>Oct'20 - Nov'21</p>
              <p>
                Jayaho.io <span>(Frontend Developer)</span>{" "}
              </p>
              <p>
                Worked on the frontend of various verticals under Jayaho. Built logic driven and responsive coding
                aptitude games for kids and a cms for managing the content on the website
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className={styles.skills} id="skills">
        <div className={styles.heading}>Skills</div>
        <div className={styles.skill}>
          <p>React</p>
          <div>
            <div style={{ width: "100%" }}></div>
          </div>
        </div>
        <div className={styles.skill}>
          <p>React Native</p>
          <div>
            <div style={{ width: "95%" }}></div>
          </div>
        </div>
        <div className={styles.skill}>
          <p>Next.js</p>
          <div>
            <div style={{ width: "100%" }}></div>
          </div>
        </div>
        <div className={styles.skill}>
          <p>NodeJS</p>
          <div>
            <div style={{ width: "90%" }}></div>
          </div>
        </div>
        <div className={styles.skill}>
          <p>MySQL</p>
          <div>
            <div style={{ width: "90%" }}></div>
          </div>
        </div>
        <div className={styles.skill}>
          <p>MongoDB</p>
          <div>
            <div style={{ width: "90%" }}></div>
          </div>
        </div>
        <div className={styles.skill}>
          <p>JavaScript</p>
          <div>
            <div style={{ width: "95%" }}></div>
          </div>
        </div>
      </div>
    </div>
  );
}
